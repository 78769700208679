/* TODO: BRANDED SITE: set branding color theme */

/* .ocmd {
    --primary-color: #00767A;
    --secondary-color: #25953B;
    --primary-button-bg: #440667;
    --primary-light-color: #fae8e9;
    --primary-dark-color: #d4e5f1;
 } */
 .paymentportal {
    --primary-color: #044271;
    --secondary-color: #ffb34d;
    --primary-button-bg: #044271;
    --primary-light-color: #EBF6FE;
    --primary-dark-color: #d4e5f1;
 }
 
 .aceparking {
    --primary-color: #1c3e78;
    --secondary-color: #61ceea;
    --primary-button-bg: #1c3e78;
    --primary-light-color: #d5e5ff;
    --primary-dark-color: #d4e5f1;
 }
 
 .sppluscorporation {
    --primary-color: #d3323a;
    --secondary-color: #f8a81b;
    --primary-button-bg: #d3323a;
    --primary-light-color: #fae8e9;
    --primary-dark-color: #d4e5f1;
 }
 
 .duncansolutions {
    --primary-color: #00767a;
    --secondary-color: #005d60;
    --primary-button-bg: #00767a;
    --primary-light-color: #e1f5f6;
    --primary-dark-color: #d4e5f1;
 }
 
 .cityofleavenworth {
    --primary-color: #314157;
    --secondary-color: #f8b956;
    --primary-button-bg: #314157;
    --primary-light-color: #bdc7d4;
    --primary-dark-color: #bdc7d4;
 }
 
 .cityoffollybeach {
    --primary-color: #689580;
    --secondary-color: #dcb858;
    --primary-button-bg: #689580;
    --primary-light-color: #daf0e6;
    --primary-dark-color: #daf0e6;
 }
 .mysticct{
    --primary-color:#243f87;
    --secondary-color:#4ea5dd;
    --primary-button-bg:#243f87;
    --primary-light-color:#e1eef6;
    --primary-dark-color:#e1eef6; 
 }
 
 .mansfield{
    --primary-color: #252a68;
    --secondary-color: #76be45;
    --primary-button-bg: #252a68;
    --primary-light-color: #dfe1f7;
    --primary-dark-color: #dfe1f7;
 }
 
 .greenvillesc{
    --primary-color: #10684c;
    --secondary-color: #fcb034;
    --primary-button-bg: #10684c;
    --primary-light-color: #cef4e8;
    --primary-dark-color: #cef4e8;
 }
 
 .bellingham{
    --primary-color: #10684c;
    --secondary-color: #fcb034;
    --primary-button-bg: #10684c;
    --primary-light-color: #cef4e8;
    --primary-dark-color: #cef4e8;
 }
 
 .ocmd{
    --primary-color: #436186;
    --secondary-color:#6d553a;
    --primary-button-bg: #436186;
    --primary-light-color: #e4effd;
    --primary-dark-color:#e4effd;
 
 }

 .southmiami{
   --primary-color:#017939;
   --secondary-color:#fa8b1f;
   --primary-button-bg:#017939;
   --primary-light-color:#e4f4fd;
   --primary-dark-color:#e4f4fd;
 }
 
 .lazparking{
    --primary-color: #b20838;
    --secondary-color: #919191;
    --primary-button-bg: #b20838;
    --primary-light-color: #f6d8e1;
    --primary-dark-color:#f6d8e1;
 }
 
 .kalamazoo{
    --primary-color:#177bc1;
    --secondary-color:#545454;
    --primary-button-bg:#177bc1;
    --primary-light-color:#d2e7f5;
    --primary-dark-color:#d2e7f5;
 }
 .bismarck{
    --primary-color: #2a5d8f;
    --secondary-color: #5a8010;
    --primary-button-bg: #2a5d8f;
    --primary-light-color: #cbddef;
    --primary-dark-color: #cbddef;
 }
 .parkiop{
    --primary-color:#1aae4d;
    --secondary-color:#040404;
    --primary-button-bg:#1aae4d;
    --primary-light-color:#d7fae3;
    --primary-dark-color:#d7fae3;
 }
 .parkbangor{
    --primary-color:#175191;
    --secondary-color:#d93442;
    --primary-button-bg:#175191;
    --primary-light-color:#c7e0fc;
    --primary-dark-color:#c7e0fc;
 }
 
 .parkriveroaks {
    --primary-color: #b20838;
    --secondary-color:#bec0c2;
    --primary-button-bg: #b20838;
    --primary-light-color: #f6d8e1;
    --primary-dark-color:#f6d8e1;
 }
 
 .ashland {
    --primary-color:#126899;
    --secondary-color:#eeb95b;
    --primary-button-bg:#126899;
    --primary-light-color:#d7eaf5;
    --primary-dark-color:#d7eaf5;
 }
 
 .beaufort{
    --primary-color:#00599b;
    --secondary-color:#ffc758;
    --primary-button-bg:#00599b;
    --primary-light-color:#d8edfc;
    --primary-dark-color:#d8edfc;
 }
 
 .hartfordparkingauthority{--primary-color:#006aa8;
    --secondary-color:#eeb95b;
    --primary-button-bg:#006aa8;
    --primary-light-color:#e2f0f8;
    --primary-dark-color:#e2f0f8;}

.parkcohasset{
      --primary-color:#000000;
      --secondary-color:#969696;
      --primary-button-bg:#000000;
      --primary-light-color:#dfdfdf;
      --primary-dark-color:#dfdfdf;
}

.parkloyalty {
   --primary-color: #044271;
   --secondary-color: #ffb34d;
   --primary-button-bg: #044271;
   --primary-light-color: #EBF6FE;
   --primary-dark-color: #d4e5f1;
}

.peakparking {
   --primary-color:#000000;
   --secondary-color:#969696;
   --primary-button-bg:#000000;
   --primary-light-color:#dfdfdf;
   --primary-dark-color:#dfdfdf;
}

.parksepta{
   --primary-color:#1f4fa3;
   --secondary-color:#f14728;
   --primary-button-bg:#1f4fa3;
   --primary-light-color:#ecf2fd;
   --primary-dark-color:#ecf2fd;
}

.parkcharleston{
   --primary-color:#000;
   --secondary-color:#818181;
   --primary-button-bg:#000;
   --primary-light-color:#eeeeee;
   --primary-dark-color:#eeeeee;
}

.parkkcmo {
   --primary-color:#ff4b3b;
   --secondary-color:#2093d2;
   --primary-button-bg:#ff4b3b;
   --primary-light-color:#f9dedc;
   --primary-dark-color:#f9dedc;
}

.parkephratapa{
   --primary-color:#181718;
   --secondary-color:#706e70;
   --primary-button-bg:#181718;
   --primary-light-color:#ececec;
   --primary-dark-color:#ececec;
}

.rutgersuniversity{
   --primary-color:#e51937;
   --secondary-color:#2c2c2c;
   --primary-button-bg:#e51937;
   --primary-light-color:#f7d6db;
   --primary-dark-color:#f7d6db;
}

.cliftonheights{
   --primary-color:#e5ba46;
   --secondary-color:#9cb7d2;
   --primary-button-bg:#f3e9d9;
   --primary-light-color:#e4f4fd;
   --primary-dark-color:#f3e9d9;
 }

 .eastonpreferredpark {
   --primary-color:#003459;
   --secondary-color:#45abf4;
   --primary-button-bg:#003459;
   --primary-light-color:#45abf4;
   --primary-dark-color:#45abf4;
 }
 .parkvolusia {
   --primary-color:#006bb6;
   --secondary-color:#80c342;
   --primary-button-bg:#ffc20d;
   --primary-light-color:#fcf1d2;
     --primary-dark-color:#fcf1d2;
 }
 .parkvirginiabeach {
   --primary-color:#f4913e;
   --secondary-color:#189ad6;
   --primary-button-bg:#f4913e;
   --primary-light-color:#fde2cd;
   --primary-dark-color:#fde2cd;
 }

 .parksatellitebeach {
   --primary-color: #165996;
   --secondary-color: #fabf42;
   --primary-button-bg: #165996;
   --primary-light-color: #d8ecff;
   --primary-dark-color: #d8ecff;
 }

 .parkchattanooga {
   --primary-color:#274189;
   --secondary-color:#60aa57;
   --primary-button-bg:#274189;
   --primary-light-color:#e2e8f9;
   --primary-dark-color:#e2e8f9;
 }

 .parksurfcity {
   --primary-color:#003997;
   --secondary-color:#00ad8c;
   --primary-button-bg:#003997;
   --primary-light-color:#e2ebfa;
   --primary-dark-color:#e2ebfa;
}